<template>
  <div>
    <EITSignUp />
  </div>
</template>

<script>
  import EITSignUp from '@/components/eye/EITSignUp.vue'

export default {
  components: {
    EITSignUp
  }
}
</script>
