<template>
  <v-container class="py-6">
    <v-responsive max-width="700" class="mx-auto text-center">
      <div class="text-overline secondary--text mb-3">Zentech</div>
      <!-- <h2 class="text-h3 text-lg-h2 mb-4">Injection Track</h2> -->
      <img src="/images/logo.png" alt="InjectionTrack" height="48" class="mr-1">
    </v-responsive>

    <v-card class="pa-2 mx-auto" max-width="600" color="transparent" flat>
      <v-card-text>
        <v-text-field v-model="email" label="Email *" solo outlined required></v-text-field>
        <v-text-field v-model="username" label="Name" solo outlined></v-text-field>
        <v-text-field type="password" v-model="password" label="Password * - 8 characters or longer" solo outlined required></v-text-field>
        <v-text-field type="password" v-model="confirm_password" label="Confirm Password *" solo outlined required></v-text-field>
        <v-text-field v-model="invite_code" label="Activation Code *" solo outlined hide-details required></v-text-field>
        <div class="mb-4"></div>
        <v-btn block class="primary" x-large @click="user_register">Sign Up</v-btn>
        <v-card-actions class="text-center">
          <span class="error" v-if="errorMessage">{{ errorMessage }}</span>
        </v-card-actions>
        <div class="text-center mt-2 text-body-2">
          <router-link to="./signin">Sign in</router-link>&nbsp;&nbsp;|&nbsp;
          <a href="mailto:info@injtrack.com">Contact us</a>
        </div>

        <!--<div class="d-flex my-3">
          <v-divider class="my-1"></v-divider>
          <div class="text-overline mx-1">Or Sign In With</div>
          <v-divider class="my-1"></v-divider>
        </div>

        <div class="d-flex justify-space-between">
          <v-btn outlined large class="flex-grow-1" color="secondary lighten-2">
            <v-icon>mdi-facebook</v-icon>
          </v-btn>
          <v-btn outlined large class="flex-grow-1 mx-2" color="secondary lighten-2">
            <v-icon>mdi-twitter</v-icon>
          </v-btn>
          <v-btn outlined large class="flex-grow-1" color="secondary lighten-2">
            <v-icon>mdi-google</v-icon>
          </v-btn>
        </div>-->
        <!-- <div class="text-overline text-uppercase mt-3 text-center">
          <v-responsive max-width="280" class="mx-auto">
            By signing in, you agree to the<br/>
            <a href="/termofservice">Terms of Service</a> & <a href="#">Privacy Policy</a>
          </v-responsive>
        </div> -->
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    data() {
      return {
        email: '',
        username: '',
        password: '',
        confirm_password: '',
        invite_code: '',
        errorMessage: ''
      };
    },
    methods: {
      user_register() {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (this.password == '' || this.email == '' || this.confirm_password == '' || this.invite_code == '') {
          this.errorMessage = 'Please fill all mandatory fields';
          return;
        }

        if (this.confirm_password !== this.password) {
          this.errorMessage = 'Passwords mismatched';
          return;
        }
        //check email validity
        if (!regex.test(this.email)) {
          this.errorMessage = 'Invalid email address';
          return;
        }

        //check password length -> 8
        if (this.password.length < 8) {
          this.errorMessage = 'Password is too short ( < 8 characters)';
          return;
        }
        //register
        this.registerToBackend();
        return;
        //check email duplicate
        if (false) {
          this.errorMessage = 'Email has already been registered';
          return;
        }
        //check invite code
        if (false) {
          this.errorMessage = 'Invalid Activation code';
          return;
        }

      },
      registerToBackend() {
        const http = require('https');
        const data = JSON.stringify({
          code: this.invite_code,
          username: this.username,
          password: this.password,
          email: this.email
        });
        const options = {
          hostname: this.$serverAddress,
          port: this.$serverPort,
          path: '/api/users/register',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Content-Length': data.length
          }
        };

        let responseBody = '';

        const req = http.request(options, (res) => {
          console.log(`status code: ${res.statusCode}`);
          if (res.statusCode == 201) {
            this.$setCookie('user', this.username);
            this.$router.push('/signin');
            return;
          }
          //else {
          //  const parsedData = JSON.parse(res);
          //  this.errorMessage = parsedData["message"];
          //}
          res.on('data', (d) => {
            console.log(d);
            responseBody += d;
          });
          res.on('error', (e) => {
            console.error(`error: ${e.message}`);
            this.errorMessage = 'Errors occurred, please contact your admin';
          });
          res.on('end', () => {
            try {
              const parsedData = JSON.parse(responseBody);
              this.errorMessage = parsedData["message"];
              // this.$router.push('/signin');
            } catch (e) {
              console.error(e.message);
              this.errorMessage = 'Errors occurred, please contact your admin';
            }
          });
        });
        // 写入数据到请求主体
        req.write(data);
        req.end();

      }
    },
  };
</script>
<style>
.error {
  color: white;
  margin-top: 10px;
  padding: 5px;
  border-radius:5px;
}
</style>
